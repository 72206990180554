import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"

export default ({ data }) => {
  const pageRender = data => (
    <Layout>
      <HeadMeta pageTitle="Request a phrase" />
      <PageHeader>Request a phrase</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Request a phrase", href: "/" },
        ]}
      />
      <p>
        Please send requests for new translations to
        Public Library Services.
      </p>
      <dl>
        { data.restNode.json.field_address && (
          <>
            <dt>Address:</dt>
            <dd>
              <div dangerouslySetInnerHTML={{ __html: data.restNode.json.field_address.value }} /> 
            </dd>
          </>
        )}
        { data.restNode.json.field_fax && (
          <>
            <dt>
              Fax:
            </dt>
            <dd>
              { data.restNode.json.field_fax }
            </dd>
          </>
        )}
        {data.restNode.json.field_email && (
          <>
          <dt>
            Email:
          </dt>
          <dd>
            <a href={`mailto:${ data.restNode.json.field_email }`}>
              { data.restNode.json.field_email }
            </a>
          </dd>
          </>
        )}
      </dl>
    </Layout>
  )

  return (
    <StaticQuery
      query={graphql`
        query RequestPageQuery {
          restNode(json: {nid: {eq: "2946"}}) {
            json {
              title
              field_address {
                value
              }
              field_email
              field_fax
            }
          }
        }
      `}
      render={pageRender}
    />
  )
}
